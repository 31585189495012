import type { ButtonBaseProps } from "./utils";
import { Loader } from "lucide-react";
import { classNames } from "~/utils/formatters";
import { forwardRef } from "react";
import { appearances, buttonSizes } from "./utils";

const Button = forwardRef(
  (
    {
      children,
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      appearance = "primary",
      block = false,
      rounded = false,
      size = "md",
      status = "idle",
      type = "submit",
      loadingText,
      ...buttonProps
    }: {
      type?: "submit" | "button" | "reset";
      status?: keyof (typeof appearances)["primary"][1];
      loadingText?: string;
    } & ButtonBaseProps &
      Omit<
        React.DetailedHTMLProps<
          React.ButtonHTMLAttributes<HTMLButtonElement>,
          HTMLButtonElement
        >,
        "children"
      >,
    ref: React.Ref<HTMLButtonElement>,
  ) => (
    <button
      type={type}
      ref={ref}
      disabled={status === "idle" ? false : true}
      className={classNames(
        block ? "flex w-full" : "inline-flex",
        rounded ? "rounded-full" : "rounded-3xl",
        buttonSizes[size][0],
        appearances[appearance][0],
        appearances[appearance][1][status],
        "relative items-center justify-center text-base font-bold transition focus:outline-none focus:ring-2 focus:ring-sky-400",
      )}
      {...buttonProps}
    >
      {status === "loading" && !loadingText ? (
        <div className="absolute inset-0 grid h-full w-full place-items-center rounded-3xl">
          <Loader
            className="h-6 w-6 animate-spin text-white"
            aria-label="Loading..."
          />
        </div>
      ) : null}
      {status === "loading" && loadingText ? (
        <Loader
          aria-label="Loading..."
          className={classNames(
            buttonSizes[size][1]["leftIcon"],
            "animate-spin text-white",
          )}
        />
      ) : LeftIcon ? (
        <LeftIcon className={buttonSizes[size][1]["leftIcon"]} />
      ) : null}
      {status === "loading" && loadingText ? (
        <span className="text-white">{loadingText}</span>
      ) : (
        children
      )}
      {RightIcon ? (
        <RightIcon className={buttonSizes[size][1]["rightIcon"]} />
      ) : null}
    </button>
  ),
);
if (process.env.NODE_ENV === "development") {
  Button.displayName = "Button";
}

export { Button };
